body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Futura';
  src: url('/fonts/Futura.ttf') format('truetype'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype'); /* Legacy iOS */
}
@font-face {
  font-family: 'Roboty';
  src: url('/fonts/roboto/Roboto-Regular.woff') format('woff'); /* Legacy iOS */
}

html { 
  background: url("/images/bg_steel.png") no-repeat center center fixed;
  background-size: cover;
  text-align: left;
  overflow-y: scroll;
  /* margin-right: 16px; */
}

body {
  /* width: calc(100vw - 16px); */
}

h2 {
  font-size: 25px;
  color: #01507e;
  text-transform: uppercase;
  margin: 40px 0 20px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  text-align: left;
}

h3 {
  font-size: 18px;
  color: #01507e;
  text-transform: uppercase;
  margin: 40px 0 20px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  text-align: left;
}

h4 {
  font-size: 14px;
  color: white;
  background-color: #003465;
  text-transform: uppercase;
  margin: 40px 0 20px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  text-align: left;
  width: 100%;
}


.App {
  text-align: center;
  font: 9pt 'Open Sans', sans-serif;
  margin: 60px 20px;
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

[class*="Button__button___"] {
  background-color: #003465 !important;
}
[class*="Anchor__a"] {
  color: #003465 !important;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

.hidden {
  display: none !important;
}

.noscroll {
  overflow: none;
}

#qqCanvas {
  width: 100vw;
  height: 100vh;
  background-color: #f3f8fc;
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -50px -50px, -50px -50px, -50px -50px, -50px -50px;
  background-image: linear-gradient(white 2px, transparent 2px),
                  linear-gradient(90deg, white 2px, transparent 2px),
                  linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
                  linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
}
canvas[resize] {
  width: 100%;
  height: 100%;
}

#qqWebGL {
  width: 100%;
  height: 100%;
  touch-action: none;
}
